<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixMediaFieldInterface, VoixNumberFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'
import HeroBackground from '@/components/blocks/HeroBackground.vue'

const props = defineProps<{
  fields: {
    image: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    subtitle: VoixTextFieldInterface
    description: VoixWysiwygFieldInterface
    height: VoixNumberFieldInterface
    mode: VoixSelectFieldInterface
    showTitle: VoixCheckboxFieldInterface
  }
}>()
defineSlice({
  name: {
    label: 'Blocks Hero',
    group: 'blocks',
    layouts: ['*'],
  },

  preview: 'SlicesBlocksBlocksHero.jpg',

  fields: {
    image: {
      type: 'media',
      label: 'Image',
      breakpoints: {
        'default': { width: 600 },
        'md': { width: 800 },
        'lg': { width: 1200 },
        '2xl': { width: 2000 },
      },
      name: 'image',
    },
    showTitle: { type: 'checkbox', label: 'Show Title', default: true, name: 'showTitle' },
    title: { type: 'text', label: 'Title', name: 'title' },
    subtitle: { type: 'text', label: 'Subtitle', name: 'subtitle' },
    description: { type: 'wysiwyg', label: 'Description', name: 'description' },
    height: { type: 'number', label: 'Height', default: 40 },
    mode: { type: 'select', label: 'Mode', options: {
      left: 'left',
      bottomLeft: 'Bottom Left',
      right: 'Right',
      bottomRight: 'Bottom Right',
    }, default: 'bottomLeft', name: 'mode' },
    bottomGradient: {
      type: 'color',
      label: 'Bottom Gradient',
      enabled: false,
      default: '#ffffff',
    },
  },
  slots: [],
})

const isVisible = ref(false)
function onVisibilityChange(entry: { intersectionRatio: number }) {
  if (entry.intersectionRatio * 10 > 1)
    isVisible.value = true
  else
    isVisible.value = false
}
function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: Number.parseInt(result[1], 16),
        g: Number.parseInt(result[2], 16),
        b: Number.parseInt(result[3], 16),
      }
    : null
}
const gradientStyles = computed(() => {
  const rgb = hexToRgb(props.fields.bottomGradient.value) ?? { r: 255, g: 255, b: 255 }

  return {
    background: `linear-gradient(0deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1) 20%, rgba(0, 0, 0, 0) 80%)`,
  }
})
</script>

<template>
  <div
    v-voix-visibility="{
      callback: onVisibilityChange, threshold: [0, 0.25],
    }"
    class="relative"
  >
    <VoixMedia v-slot="slotProps" :field="props.fields.image" :background="true">
      <HeroBackground :image="slotProps.image" :height="parseInt(fields.height.value) || 40">
        <div
          v-if="fields.showTitle.value" class="absolute inset-0 transition-all duration-1000 ease-out" :class="{
            'opacity-0': !isVisible,
            'opacity-100': isVisible,
          }"
        >
          <div
            :class="{
              'absolute bottom-0 left-0 -mb-12': fields.mode.value === 'bottomLeft',
              'absolute bottom-0 right-0 -mb-12': fields.mode.value === 'bottomRight',
              'absolute md:top-0 left-0 bottom-0 md:bottom-0 flex items-center': fields.mode.value === 'left',
              'absolute md:top-0 right-0 bottom-0 md:bottom-0 flex items-center': fields.mode.value === 'right',
            }"
          >
            <div
              class="container  bg-white py-12 text-gray-700 max-w-xl"
            >
              <div class="text-center md:text-left max-w-xl  prose-headings prose-p:pb-4">
                <h1 class="text-2xl tracking-wide md:text-4xl md:leading-[3rem]">
                  <span class="block text-xs font-light tracking-tight text-gray-700">
                    {{ fields.subtitle.value }}
                  </span>
                  {{ fields.title.value }}
                </h1>
                <VoixWysiwyg v-if="fields.description.value" :field="fields.description" class="font-light" />
              </div>
            </div>
          </div>
        </div>
      </HeroBackground>
    </VoixMedia>
    <div
      v-if="fields.bottomGradient.enabled"
      :style="gradientStyles"
      class="absolute bottom-0 left-0 w-full bg-black h-48 z-10"
    />
  </div>
</template>

<style scoped>
.hero-text {
  h1 {
    @apply text-white font-light;
  }
}
</style>
